import { getCustomer } from "@/graphql/queries";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { API, graphqlOperation } from "aws-amplify";
import { isLocalHost } from "@/utils/util";

export const getCustomerInfo = async (payload: {
    merchantId: string;
    customerId: string;
}) => {
    let params = {
        customerId: payload.customerId, 
    };
    if (isLocalHost()) {
        params["merchantId"] = payload.merchantId;
    }
    try {
        const res = (await API.graphql(
            graphqlOperation(getCustomer, params)
        )) as GraphQLResult<any>;
        return res.data.getCustomer;
    } catch (error) {
        throw error;
    }
};
